<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'JoyfulFireflies',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/single-joyfulfireflies.png',
      name: 'Joyful Fireflies',
      type: 'single',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/track/joyful-fireflies',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/intl-fr/album/2GJPByjq5mX5zGrFyHJheV',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=yOwPEtR7HVs',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://www.deezer.com/en/album/702183231',
          btnLabel: 'Listen'
        },
        {
          service: 'amazon',
          url: 'https://www.amazon.fr/music/player/albums/B0DTVQJLQR',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'https://tidal.com/browse/album/413435381',
          btnLabel: 'Listen'
        }
      ]
    }
  }
}
</script>